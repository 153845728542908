import styled from '../../../theme/styled-with-theme';
import { Box } from '../../Box';
import { Column } from '../../Layout';
import { UnstyledButton } from '../../Button';
import { mediaQueries } from '../../../styles/media-queries';
import { SpacingToken } from '../../../theme/tokens/spacing';
import { pxToRem } from '../../../styles/utils';

export const CloseIconPositioner = styled('div')`
  cursor: pointer;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing.m};
`;

export const ColumnContainer = styled(Column)`
  padding-top: ${({ theme }) => theme.spacing.m};
  ${mediaQueries.desktop} {
    align-self: middle;
  }
`;

const IFRAME_PADDING_X: SpacingToken = 'sm';
const IFRAME_PADDING_X_TABLET: SpacingToken = 'xl';
const IFRAME_MAX_HEIGHT = '80vh';
const VIDEO_ASPECT_RATIO = 0.5625;

export const Iframe = styled('iframe')`
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const IframePositioner = styled('div')`
  max-width: calc(
    ${IFRAME_MAX_HEIGHT} / ${VIDEO_ASPECT_RATIO} -
      (${({ theme }) => theme.spacing[IFRAME_PADDING_X]} * 2)
  );
  max-height: ${IFRAME_MAX_HEIGHT};
  position: absolute;
  width: calc(100% - (2 * ${({ theme }) => theme.spacing[IFRAME_PADDING_X]}));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${mediaQueries.tablet} {
    max-width: calc(
      ${IFRAME_MAX_HEIGHT} / ${VIDEO_ASPECT_RATIO} -
        (${({ theme }) => theme.spacing[IFRAME_PADDING_X_TABLET]} * 2)
    );
    width: calc(
      100% - (2 * ${({ theme }) => theme.spacing[IFRAME_PADDING_X_TABLET]})
    );
  }
`;

export const IframeWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  padding-top: ${VIDEO_ASPECT_RATIO * 100}%;
`;

export const PlayIcon = styled('svg')`
  cursor: pointer;
  max-width: 100%;
  z-index: 0;
  margin: auto;
`;

export const TextWrapper = styled(Box)`
  width: 100%;

  ${mediaQueries.tablet} {
    width: auto;
    flex: 1;
  }
`;

export const Video = styled.video`
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

export const VideoButton = styled(UnstyledButton)`
  z-index: 1;
  outline: 0;
  position: absolute;
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  bottom: ${({ theme }) => theme.spacing.xl};
  left: ${({ theme }) => theme.spacing.xl};

  .playIconBackground,
  .playIconCenter {
    transition: 0.2s fill ease;
  }

  &:hover .playIconBackground,
  &:focus .playIconBackground {
    fill: ${({ theme }) => theme.color.playIconCenter};
  }

  &:hover .playIconCenter,
  &:focus .playIconCenter {
    fill: ${({ theme }) => theme.color.playIconBackground};
  }

  ${mediaQueries[0]} {
    bottom: ${({ theme }) => theme.spacing.ml};
    left: ${({ theme }) => theme.spacing.ml};
  }

  ${mediaQueries.desktop} {
    bottom: ${({ theme }) => theme.spacing['2xl']};
    left: ${({ theme }) => theme.spacing['2xl']};
  }
`;
