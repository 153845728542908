import React from 'react';
import {
  StyledContainer,
  StyledInitials,
  StyledAvatar,
  StyledHighlightText,
  StyledTextContainer
} from './styles';

interface ProfileAvatarWithTextProps {
  imageUrl: string | undefined;
  name: string;
  text: string;
}

export const ProfileAvatarWithText = ({
  imageUrl,
  name,
  text
}: ProfileAvatarWithTextProps) => {
  const getProfileImage = () => {
    if (!imageUrl) {
      const firstLetter = name.charAt(0).toUpperCase();
      return <StyledInitials>{firstLetter}</StyledInitials>;
    } else {
      return <StyledAvatar src={imageUrl} alt={`${name} profile avatar`} />;
    }
  };

  return (
    <>
      {name && (
        <StyledContainer>
          {getProfileImage()}
          <StyledTextContainer>
            <div>
              <p>{text}</p>
              <StyledHighlightText>{name}</StyledHighlightText>
            </div>
          </StyledTextContainer>
        </StyledContainer>
      )}
    </>
  );
};
