import React from 'react';

import { IEyebrowFields } from '@/types/contentful';

import { Text } from '../Text';

import {
  EyebrowContainer,
  EyebrowImage,
  EyebrowArrow
} from '../HomepageHero/styles';

import arrowRight from '@/images/arrow-right.svg';

export function EyebrowHomepage({ text, image, link }: IEyebrowFields) {
  return (
    <EyebrowContainer
      data-testid="eyebrow-link"
      alignItems="center"
      padding="s"
      paddingRight={link ? 's' : 'sm'}
      margin="auto"
      eyebrowLink={!!link}
      onClick={() => {
        if (link) {
          window.location.href = link;
          return;
        }
      }}
    >
      {image?.fields && (
        <EyebrowImage
          src={image?.fields?.file?.url || undefined}
          alt={image?.fields?.description || ''}
        />
      )}
      <Text
        as="p"
        fontSize="p_0"
        color="white"
        fontWeight="bold"
        paddingLeft="s"
      >
        {text}
      </Text>
      {link && <EyebrowArrow src={arrowRight} alt={`Navigate to ${text}`} />}
    </EyebrowContainer>
  );
}
