import styled from '../../theme/styled-with-theme';
import { BlockContainer, BlockContainerProps } from '../Layout';
import { UnstyledButton } from '../Button';

interface Props extends BlockContainerProps {
  backgroundImage?: string;
}

export const Modal = styled('div')`
  display: flex;
  position: fixed;
  inset: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const BlockContainerBgImage = styled(BlockContainer)<Props>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const VideoButton = styled(UnstyledButton)`
  z-index: 1;
  outline: 0;

  .playIconBackground,
  .playIconCenter {
    transition: 0.2s fill ease;
  }

  &:hover .playIconBackground,
  &:focus .playIconBackground {
    fill: ${({ theme }) => theme.color.playIconCenter};
  }

  &:hover .playIconCenter,
  &:focus .playIconCenter {
    fill: ${({ theme }) => theme.color.playIconBackground};
  }
`;

export const PlayIcon = styled('svg')`
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const Iframe = styled('iframe')`
  border: 0;
  flex: 1;
  border: 0;
`;
