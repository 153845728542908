import React from 'react';

import { getImageData } from '../../../utils/contentful';

import { StyledImage } from './ImageAsset.styles';
import { AssetProps } from '../shared';
import cx from 'classnames';

export function ImageAsset({ asset, rounded }: AssetProps) {
  return (
    <div className="relative w-full tablet:w-auto tablet:grow-[1.5]">
      <StyledImage
        {...getImageData(asset)}
        data-blockid="assetWith5050Text-image"
        className={cx('', {
          'rounded-3xl tablet:rounded-4xl': rounded
        })}
      />
    </div>
  );
}
