import React from 'react';
import { IEyebrowFields } from '@/types/contentful';
import { UserData } from '../../lib/referrals/getUserDataById';
import { ProfileAvatarWithText } from './ProfileAvatarWithText';
import { EyebrowHomepage } from './EyebrowHomepage';
import { Box } from '../Box';
import { Text } from '../Text';

interface EyebrowProps {
  eyebrowFields: IEyebrowFields;
  advocateData?: UserData | null;
}
export const Eyebrow = ({ eyebrowFields, advocateData }: EyebrowProps) => {
  if (eyebrowFields.type === 'InvitedBy') {
    return (
      <ProfileAvatarWithText
        imageUrl={advocateData?.avatar?.signedRequestList[0]}
        name={advocateData?.name || ''}
        text={eyebrowFields.text || ''}
      />
    );
  } else if (eyebrowFields.type === 'text-only') {
    return (
      <Box marginBottom={{ 0: 'm' }}>
        <Text
          as="p"
          lineHeight={{ 0: 'm' }}
          fontWeight={{ 0: '500' }}
          fontSize={{ 0: 'sm' }}
          data-blockid="assetWith5050Text-superhead"
          data-testid="assetWith5050Text-superhead"
          style={{ textTransform: 'uppercase', letterSpacing: '1px' }}
        >
          {eyebrowFields.text}
        </Text>
      </Box>
    );
  } else {
    return <EyebrowHomepage {...eyebrowFields} />;
  }
};
