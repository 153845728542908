import React, { useState } from 'react';

import { trackWithFingerprint } from '../../../utils/trackWithFingerprint';
import { getImageData } from '../../../utils/contentful';

import { Lightbox } from '../../VideoBlock/Lightbox';

import { PlayIcon, VideoButton } from './styles';
import { StyledImage } from '../ImageAsset/ImageAsset.styles';
import { AssetProps } from '../shared';
import cx from 'classnames';

interface VideoAssetProps extends AssetProps {
  vimeoId?: string;
}

export function VideoAsset({
  vimeoId,
  title,
  asset,
  rounded
}: VideoAssetProps) {
  const [showingVideo, setShowingVideo] = useState(false);

  const toggleVideo = (displayVideo: boolean) => {
    setShowingVideo(displayVideo);
    if (displayVideo) {
      trackWithFingerprint('Video Opened', {
        page_url: window.location.href
      });
    } else {
      trackWithFingerprint('Video Closed', {
        page_url: window.location.href
      });
      document.getElementById('gl-video-play-button')?.focus();
    }
  };

  return (
    <div className="relative w-full" data-blockid="videoWrapper">
      <StyledImage
        {...getImageData(asset)}
        className={cx('', {
          'rounded-3xl tablet:rounded-4xl': rounded
        })}
        data-blockid="assetWith5050Text-image"
      />
      <VideoButton
        id="gl-video-play-button"
        onClick={(e: any) => {
          toggleVideo(true);
          e.currentTarget.blur();
        }}
        aria-label="Play video"
        data-testid="videoWith5050Text-button"
      >
        <PlayIcon
          width="56"
          height="56"
          viewBox="0 0 56 56"
          role="img"
          data-testid="assetWith5050Text-video"
        >
          <path
            className="playIconBackground"
            d="M0 16C0 7.16344 7.16344 0 16 0H40C48.8366 0 56 7.16344 56 16V40C56 48.8366 48.8366 56 40 56H16C7.16344 56 0 48.8366 0 40V16Z"
            fill="#02454D"
          />
          <path
            className="playIconCenter"
            d="M34.6162 26.4677C35.6162 27.0451 35.6162 28.4885 34.6162 29.0658L25.4662 34.3486C24.4662 34.9259 23.2162 34.2042 23.2162 33.0495L23.2162 22.484C23.2162 21.3293 24.4662 20.6076 25.4662 21.185L34.6162 26.4677Z"
            fill="#3BBFAD"
          />
        </PlayIcon>
      </VideoButton>
      {vimeoId && title && (
        <Lightbox
          title={title}
          showingVideo={showingVideo}
          setShowingVideo={toggleVideo}
          vimeoId={vimeoId}
        />
      )}
    </div>
  );
}
