import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';

export const StyledInitials = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.playIconBackground};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.h4};

  ${mediaQueries.tablet} {
    width: ${pxToRem(72)};
    height: ${pxToRem(72)};
  }
`;

export const StyledAvatar = styled('img')`
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border-radius: 50%;
  object-fit: cover;
  ${mediaQueries.tablet} {
    width: ${pxToRem(72)};
    height: ${pxToRem(72)};
  }
`;

export const StyledContainer = styled('div')`
  margin-bottom: ${pxToRem(24)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
`;

export const StyledTextContainer = styled('div')`
  display: flex;
  flex-flow: column;
  font-size: ${({ theme }) => theme.fontSize.m};
  line-height: ${pxToRem(26)};
  margin-left: ${pxToRem(16)};
`;

export const StyledHighlightText = styled('p')`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.h4};
`;
