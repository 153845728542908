import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';

import greenCheckmark from '@/images/green-checkmark-circle.svg';

export const StyledUnorderedList = styled('ul')`
  padding: 0;
  margin-bottom: ${pxToRem(24)};

  li {
    background: url(${greenCheckmark.src}) no-repeat;
    background-size: ${pxToRem(24)} ${pxToRem(24)};
    padding: 0 0 0 ${pxToRem(32)};
    margin: 0 0 ${pxToRem(16)} 0;
    list-style: none;
  }

  li > p {
    line-height: ${pxToRem(24)};
  }

  li > ul:first-of-type {
    padding: 0 0 0 ${pxToRem(32)};
  }

  li > ul:first-of-type > li {
    background: none;
    list-style-type: disc;
    padding-left: 0;
    margin-bottom: ${pxToRem(8)};
  }
`;

export const UnorderedList = styled('ul')`
  margin-left: -${pxToRem(8)};

  li {
    list-style-type: disc;
    margin: 0 0 0 -${pxToRem(8)};
    padding: 0 0 ${pxToRem(8)} 0;
  }

  li > ul:first-of-type {
    padding: 0 0 0 ${pxToRem(40)};
    margin-bottom: 0;
  }

  li > ul:first-of-type > li {
    background: none;
    list-style-type: disc;
    margin-bottom: 0;
  }
`;
