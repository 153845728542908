import { IImageLinkFields } from '@/types/contentful';
import { Asset } from 'contentful';
import { getRedirectByEnv } from 'src/utils/buildWebRegUrl';
import { getImageDataWithHttps } from 'src/utils/contentful';
import GlImage, { GlImageProps } from '../Image';
import Link, { BaseLinkProps } from '../Link';

export type Ref = HTMLAnchorElement | null;

interface LinkingOptionProps extends IImageLinkFields {
  linkProps?: Partial<BaseLinkProps>;
  glImageProps?: Partial<GlImageProps>;
  rounded?: boolean;
  children?: (image: Asset) => React.ReactElement;
}

export default function ({
  linkProps,
  image,
  glImageProps,
  destination,
  children
}: LinkingOptionProps) {
  return (
    <Link
      {...{
        href: getRedirectByEnv(destination.fields),
        ...linkProps
      }}
    >
      {children ? (
        children(image)
      ) : (
        <GlImage
          {...{ ...getImageDataWithHttps(image as Asset), ...glImageProps }}
        />
      )}
    </Link>
  );
}
