import React, { useEffect, useRef } from 'react';
import Image from 'next/legacy/image';

import { usePublicRuntimeConfig } from '../../hooks/usePublicRuntimeConfig';

import { Modal, Iframe } from './styles';

import closeIcon from '@/images/x-icon.svg';
import { useEscapeEvent } from '../../hooks/useEscapeEvent';
import { useRefClickEvent } from '../../hooks/useRefClickEvent';

interface Props {
  title: string;
  vimeoId: string;
  showingVideo: boolean;
  setShowingVideo: (showing: boolean) => void;
}

export function Lightbox({
  title,
  showingVideo,
  setShowingVideo,
  vimeoId
}: Props) {
  const { vimeoToken } = usePublicRuntimeConfig();
  const modalRef = useRef<HTMLDivElement>(null);

  const videoNodeId = `video-${vimeoId}`;

  useEscapeEvent(() => setShowingVideo(false));
  useRefClickEvent(modalRef, () => setShowingVideo(false));

  useEffect(() => {
    if (showingVideo) {
      initVimeoAnalytics();
    }
  }, [showingVideo]);

  const initVimeoAnalytics = async () => {
    if ((window as any).Vimeo && showingVideo) {
      const iframe = document.querySelector(`#${videoNodeId}`);
      const player = new (window as any).Vimeo.Player(iframe);
      const { VimeoAnalytics } = await import(
        '@segment/analytics.js-video-plugins/dist/index.umd.js'
      );
      const vimeoAnalytics = new VimeoAnalytics(player, vimeoToken);
      vimeoAnalytics.initialize();
    }
  };

  return (
    <>
      {showingVideo && (
        <Modal
          aria-modal="true"
          aria-label="Vimeo Video Modal"
          aria-labelledby={title}
        >
          <div className="relative flex h-[90vh] w-[90vw] items-center justify-center">
            <div
              ref={modalRef}
              className="relative flex h-full flex-1 flex-col overflow-hidden"
            >
              <button
                aria-label="Vimeo Video Modal Button"
                className="absolute right-0 w-5 cursor-pointer"
                onClick={() => setShowingVideo(false)}
              >
                <Image src={closeIcon} alt="close video" />
              </button>

              <Iframe
                id={videoNodeId}
                src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1`}
                frameBorder="0"
                allowFullScreen={true}
                title={title}
                className="iframe"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
